import { useEffect, useState } from "react";

// Material Components
import MDBox from "components/MDBox";
import Author from "components/Table/Author";
import FDropdown from "components/Dropdown/FDropdown";

export default function ReportOverviewData(
  reportOverviewData,
  handleReportStatusUpdate,
  newStateStatus,
  permission
) {
  const [overViewRows, setOverViewRows] = useState([]);
  const data = [
    {
      project: reportOverviewData?.project?.title,
      report: reportOverviewData?.report?.title,
      location: reportOverviewData?.location?.title,
      assets: reportOverviewData?.asset,
    },
  ];
  useEffect(() => {
    if (reportOverviewData) {
      const list = data?.map((item, index) => {
        const temp = {
          srNo: <Author name={index + 1} />,
          project: <Author name={item?.project} />,
          report: <Author name={item?.report} />,
          location: <Author name={item?.location} />,
          status: (
            <MDBox>
              <FDropdown
                variant="standard"
                id="status"
                name="status"
                value={newStateStatus}
                disabled={!permission?.update}
                menu={["open", "closed", "submitted"]}
                handleChange={(name, value, id) =>
                  handleReportStatusUpdate({ target: { name, value, id } })
                }
              />
            </MDBox>
          ),
          assets: (
            <Author
              name={item.assets?.map(
                (elem, i, array) => `${elem.cableName}${i < array.length - 1 ? ", " : ""}`
              )}
            />
          ),
        };
        return temp;
      });
      setOverViewRows([...list]);
    }
  }, [reportOverviewData, permission]);

  return {
    overViewCoumns: [
      { Header: "Project", accessor: "project", align: "left" },
      { Header: "Report", accessor: "report", align: "left" },
      { Header: "Location", accessor: "location", align: "left" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Assets", accessor: "assets", align: "center" },
    ],

    overViewRows,
  };
}

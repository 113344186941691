import React, { useEffect, useState } from "react";

// MUI Components
import MDBox from "components/MDBox";

// Custom Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import DeleteModal from "examples/modal/deleteModal/deleteModal";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import ResetFilterButton from "components/Buttons/ResetButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import Report from "layouts/report/data/reportData";
import DataTable from "examples/Tables/DataTable";

// Constant
import Constants, {
  Icons,
  PageTitles,
  FeatureTags,
  defaultData,
  Colors,
  ModalContent,
} from "utils/Constants";
import pxToRem from "assets/theme/functions/pxToRem";

// Redux component
import { useDispatch, useSelector } from "react-redux";
import {
  getAllReportTypes,
  getAllReports,
  exportReportThunk,
  deleteReport,
} from "redux/Thunks/Report";
import { projectListThunk, locationListByIdThunk } from "redux/Thunks/FieldsData";
import { Feature } from "flagged";
import { reloadReport } from "redux/Slice/Report";
import { paramCreater } from "utils/methods/methods";
import { openSnackbar } from "redux/Slice/Notification";

function Reports() {
  const mongooseId = "_id";
  const [filters, setFilters] = useState([
    {
      inputLabel: "Project",
      list: [{ [mongooseId]: "all", title: "All" }],
      selectedValue: "all",
    },
    {
      inputLabel: "Title",
      list: [{ [mongooseId]: "all", title: "All" }],
      selectedValue: "all",
    },
    {
      inputLabel: "Location",
      list: [{ [mongooseId]: "all", title: "All" }],
      selectedValue: "all",
    },
    {
      inputLabel: "Status",
      list: ["All", "Open", "Submitted", "Closed"],
      selectedValue: "All",
    },
  ]);
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [next, setNext] = useState(0);
  const [reportData, setReportData] = useState({
    type: "new",
    openModal: false,
    openDeleteModal: false,
    list: [],
    body: {},
    deleteId: "",
    error: {},
    loading: false,
  });
  const reportSLice = useSelector((state) => state?.report);
  const ConfigData = useSelector((state) => state.config);
  const permission = ConfigData?.screens?.[6]?.screensInfo?.agreement;

  const dispatch = useDispatch();

  const handleOpenDeleteModal = (reportId) =>
    setReportData({ ...reportData, openDeleteModal: true, deleteId: reportId });

  const handleDownloadReport = async (reportId, format = "pdf") => {
    const currentDate = new Date();
    const filename = `reynard_report_${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.${format}`;

    const res = await dispatch(exportReportThunk(reportId));
    const url = window.URL.createObjectURL(res.payload);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const { columns, rows } = Report(
    reportSLice.reportList,
    permission,
    handleOpenDeleteModal,
    handleDownloadReport
  );

  const handleFilter = async (filterVale = filters) => {
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
      project: filterVale[0].selectedValue,
      report: filterVale[1].selectedValue,
      location: filterVale[2].selectedValue.toLowerCase().replace(/ /g, "_"),
      status: filterVale[3].selectedValue.toLowerCase().replace(/ /g, "_"),
    };
    Object.keys(paramData).forEach((key) => {
      if (paramData[key] === "") {
        delete paramData[key];
      }
    });

    const data = new URLSearchParams(paramData);
    await dispatch(getAllReports(data));
  };

  useEffect(() => {
    (async () => {
      handleFilter();
      const projectList = await dispatch(projectListThunk());
      const temp = [...filters];
      filters[0].list = [...filters[0].list, ...projectList.payload.data];
      setFilters(temp);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const paramData = {
        page: 0,
        perPage: 100,
        project: filters[0].selectedValue,
      };
      const temp = [...filters];
      if (filters[0].selectedValue !== "all") {
        const [reportTypeList, newLocationList] = await Promise.all([
          dispatch(getAllReportTypes({ param: paramCreater(paramData), byPassSlice: false })),
          dispatch(locationListByIdThunk(filters[0].selectedValue)),
        ]);
        temp[1].list = [
          {
            [Constants.MONGOOSE_ID]: "all",
            title: reportTypeList.payload.data.data.length > 0 ? "All" : "No data found",
          },
          ...reportTypeList.payload.data.data,
        ];
        temp[2].list = [
          {
            [Constants.MONGOOSE_ID]: "all",
            title: newLocationList.payload.data.length > 0 ? "All" : "No data found",
          },
          ...newLocationList.payload.data,
        ];

        // Reset the Report Setup selectedValue to "all" when Project changes
        temp[1].selectedValue = "all";
        temp[2].selectedValue = "all";
      } else {
        temp[1].list = [{ [Constants.MONGOOSE_ID]: "all", title: "All" }];
        temp[2].list = [{ [Constants.MONGOOSE_ID]: "all", title: "All" }];
      }
      setFilters(temp);
    })();
  }, [filters[0].selectedValue]);

  const handleFilterChange = (e) => {
    const temp = [...filters];
    const { value, name } = e.target;
    const index = filters.findIndex((filter) => filter.inputLabel === name);
    temp[index].selectedValue = value;

    // If the project filter changes, reset the report filter to "all"
    if (name === "Project") {
      temp[1].selectedValue = "all";
      temp[2].selectedValue = "all";
    }

    setFilters(temp);
    handleFilter(temp);
  };

  const handleResetFilter = () => {
    const temp = filters.map((filter) => ({
      ...filter,
      selectedValue: filter.list[0][mongooseId] || filter.list[0],
    }));
    setFilters(temp);
    handleFilter(temp);
  };

  const handleTablePagination = async () => {
    const paramData = {
      page: next + 1,
      perPage: tablePagination.perPage,
      project: filters[0].selectedValue,
      report: filters[1].selectedValue,
      location: filters[2].selectedValue.toLowerCase().replace(/ /g, "_"),
      status: filters[3].selectedValue.toLowerCase().replace(/ /g, "_"),
    };
    const data = new URLSearchParams(paramData);
    const res = await dispatch(getAllReports(data));
    if (res.payload.status === 200) setNext(res.payload.data.length > 0 ? next + 1 : next);
  };

  const handleReload = async () => {
    await dispatch(reloadReport());
    handleFilter();
  };

  const handleReportDelete = async () => {
    const body = {
      userReports: reportData.deleteId,
    };
    const res = await dispatch(deleteReport(body));
    if (res.payload.status === 200) {
      await dispatch(
        openSnackbar({ message: Constants.REPORT_DELETE_SUCCESS, notificationType: "success" })
      );
      setReportData({ openDeleteModal: false, deleteId: "" });
      handleReload();
    } else {
      await dispatch(
        openSnackbar({ message: Constants.REPORT_DELETE_ERROR, notificationType: "error" })
      );
      setReportData({ openDeleteModal: false, deleteId: "" });
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between">
        <PageTitle title={PageTitles.REPORT} />
        <BasicButton
          icon={Icons.RELOAD}
          background={Colors.WHITE}
          border
          color={Colors.BLACK}
          action={handleReload}
        />
      </MDBox>
      <Feature name={FeatureTags.SETUP_REPORT}>
        <>
          <MDBox display="flex" justifyContent="space-between" mt={2} mx={0}>
            <MDBox display="flex wrap" flexWrap="wrap" justifyContent="start" mt={2} mx={0}>
              {filters.map((val) => (
                <FilterDropdown
                  key={val.inputLabel}
                  label={val.inputLabel}
                  name={val.inputLabel}
                  defaultValue={val.selectedValue}
                  value={val.selectedValue}
                  handleChange={handleFilterChange}
                  menu={val.list}
                  style={{ marginLeft: pxToRem(1) }}
                  disabled={
                    (val?.inputLabel === "Location" && filters[0]?.selectedValue === "all") ||
                    (val?.inputLabel === "Title" && filters[0]?.selectedValue === "all") ||
                    (val?.inputLabel === "Location" &&
                      filters[2]?.list[0].title === "No data found") ||
                    (val?.inputLabel === "Title" && filters[1]?.list[0].title === "No data found")
                  }
                  maxContent={defaultData.MEDIUM_CONTENT_LENGTH}
                />
              ))}
              <ResetFilterButton handleReset={handleResetFilter} style={{ marginLeft: "1rem" }} />
            </MDBox>
          </MDBox>
          <MDBox mt={3} mb={3}>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
              showTotalEntries={false}
              noEndBorder
              loading={reportSLice?.reportLoading}
              licenseRequired
              currentPage={tablePagination.page}
              handleTablePagination={handleTablePagination}
              handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
            />
          </MDBox>
          {reportData.openDeleteModal && (
            <DeleteModal
              open={reportData.openDeleteModal}
              title={ModalContent.REPORT_DELETE_TITLE}
              message={ModalContent.REPORT_DELETE_MESSAGE}
              handleClose={() => setReportData({ openDeleteModal: false, deleteId: "" })}
              handleDelete={handleReportDelete}
            />
          )}
        </>
      </Feature>
    </DashboardLayout>
  );
}

export default Reports;

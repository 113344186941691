// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// 3rd party
import PropTypes from "prop-types";

// Custom Components
const { default: MDBox } = require("components/MDBox");
const { default: MDInput } = require("components/MDInput");
const { default: MDTypography } = require("components/MDTypography");

// Form Text field
export default function FTextField({
  label,
  name,
  id,
  placeholder,
  type,
  error,
  helperText,
  value,
  defaultValue,
  handleChange,
  width,
  marginBottom,
  disabled,
  onKeyPress,
  InputProps = {},
  defaultRows = 3,
}) {
  return (
    <MDBox sx={{ mt: pxToRem(8), display: "flex", flexDirection: "column", width }}>
      <MDTypography
        variant="body"
        sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054", lineHeight: "20px" }}
      >
        {label}
      </MDTypography>
      <MDInput
        sx={{
          mt: pxToRem(6),
          "& .MuiInputBase-input": {
            fontSize: "16px",
          },
          marginBottom,
        }}
        type={type}
        {...(type === "textarea" && { multiline: true, rows: defaultRows })}
        id={id}
        name={name}
        error={error}
        helperText={helperText}
        FormHelperTextProps={{
          sx: { marginLeft: 0, color: "red" },
        }}
        onKeyPress={onKeyPress}
        value={value}
        disabled={disabled}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={handleChange}
        // to allow scroll fucnctionality in textarea when field is disabled.
        onFocus={(e) => {
          if (disabled) {
            e.target.blur();
          }
        }}
        InputProps={InputProps}
      />
    </MDBox>
  );
}

FTextField.defaultProps = {
  type: "text",
  label: "",
  name: "",
  id: "",
  placeholder: "",
  error: false,
  helperText: "",
  value: "",
  defaultValue: "",
  width: "100%",
  marginBottom: 0,
  disabled: false,
  onKeyPress: () => {},
  InputProps: {},
  defaultRows: 3,
  handleChange: () => {},
};

FTextField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  width: PropTypes.string,
  marginBottom: PropTypes.string,
  disabled: PropTypes.bool,
  onKeyPress: PropTypes.func,
  InputProps: PropTypes.objectOf(PropTypes.any),
  defaultRows: PropTypes.number,
  handleChange: PropTypes.func,
};

import React, { useEffect, useState } from "react";

// Material ui
import { Divider } from "@mui/material";
import MDBox from "components/MDBox";

// Custom Components
import TrainingMatrixData from "layouts/personnelManagement/TrainingMatrix/data/TrainingMatrixData";
import DataTable from "examples/Tables/DataTable/GroupedRowTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import ResetFilterButton from "components/Buttons/ResetButton";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import ExportHOC from "examples/HigherOrderComponents/ExportHOC";

// Redux
import { useDispatch } from "react-redux";
import { projectListThunk } from "redux/Thunks/FieldsData";
import getAllCertificateType from "redux/Thunks/certificateQuestion";
import getTrainingMatrixData, {
  exportTrainingMatrixCertificate,
} from "redux/Thunks/TrainingMatrix";

// utils and constants
import Constants, {
  defaultData,
  PageTitles,
  Colors,
  Icons,
  FeatureTags,
  ButtonTitles,
} from "utils/Constants";
import { paramCreater } from "utils/methods/methods";

// 3rd party lib
import { Feature } from "flagged";
import PropTypes from "prop-types";

function TrainingMatrix({ handleExportZipFile, exportLoading }) {
  const [loadingTrainingMatrixStatus, setLoadingTrainingMatrixStatus] = useState(Constants.PENDING);
  const [traingMatrixData, setTraingMatrixData] = useState([]);
  const [certificateTypeList, setCertificateTypeList] = useState([]);
  const [filters, setFilters] = useState([
    {
      inputLabel: "Project",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "all",
    },
    {
      inputLabel: "Certificate Status",
      list: [
        { [Constants.MONGOOSE_ID]: "select", title: "Select" },
        { [Constants.MONGOOSE_ID]: "certificate_expired", title: "Certificate Expired" },
        {
          [Constants.MONGOOSE_ID]: "certificate_expire_in_30",
          title: "Certificate Expiring in 30 days",
        },
        {
          [Constants.MONGOOSE_ID]: "certificate_expire_in_60",
          title: "Certificate Expiring in 60 days",
        },
      ],
      selectedValue: "select",
    },
  ]);
  const dispatch = useDispatch();
  const exportFilters = {
    project: filters[0].selectedValue,
  };
  const fetchTrainingMatrixData = async (tempFilters = filters) => {
    const paramData = {
      project: tempFilters[0].selectedValue,
      ...(tempFilters[1].selectedValue !== "select" && { expire: tempFilters[1].selectedValue }),
    };
    const TrainingMatrixRes = await dispatch(getTrainingMatrixData(paramCreater(paramData)));
    setTraingMatrixData(TrainingMatrixRes?.payload?.data?.data || []);
    setLoadingTrainingMatrixStatus(Constants.FULFILLED);
  };

  useEffect(() => {
    (async () => {
      const paramData = {
        sort: "asc",
      };
      const [projectRes, certificateTypeRes] = await Promise.all([
        dispatch(projectListThunk()),
        dispatch(getAllCertificateType(paramCreater(paramData))),
      ]);

      const temp = [...filters];
      temp[0].list = [{ [Constants.MONGOOSE_ID]: "all", title: "All" }, ...projectRes.payload.data];
      setFilters(temp);
      setCertificateTypeList(certificateTypeRes?.payload?.data?.data || []);
      await fetchTrainingMatrixData();
    })();
  }, []);

  const { columns, rows } = TrainingMatrixData(
    traingMatrixData,
    certificateTypeList,
    filters[0].selectedValue
  );

  const handleReload = async () => {
    setLoadingTrainingMatrixStatus(Constants.PENDING);
    await fetchTrainingMatrixData();
  };

  const handleFilterType = (e) => {
    setLoadingTrainingMatrixStatus(Constants.PENDING);
    const { name, value } = e.target;
    const temp = [...filters];
    const index = temp.findIndex((val) => val.inputLabel === name);
    temp[index].selectedValue = value;
    setFilters(temp);
    fetchTrainingMatrixData(temp);
  };

  const handleResetFilter = () => {
    setLoadingTrainingMatrixStatus(Constants.PENDING);
    const tempFilters = [...filters];
    tempFilters[0].selectedValue = "all";
    tempFilters[1].selectedValue = "select";
    setFilters(tempFilters);
    fetchTrainingMatrixData(tempFilters);
  };

  const groupedData = traingMatrixData?.trainingMatrix?.reduce((acc, item) => {
    const key = Object.keys(item)[0];
    const value = Object.values(item)[0];
    const updatedAcc = { ...acc };

    if (!updatedAcc[key]) {
      updatedAcc[key] = [];
    }
    updatedAcc[key].push(value);
    return updatedAcc;
  }, {});

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        display="flex"
        flexDirection={{ md: "row", sm: "column" }}
        justifyContent={{ md: "space-between" }}
        alignItems={{ lg: "space-between", sm: "center" }}
      >
        <PageTitle title={PageTitles.TRAINING_MATRIX} />
        <BasicButton
          icon={Icons.RELOAD}
          background={Colors.WHITE}
          border
          color={Colors.BLACK}
          action={handleReload}
        />
      </MDBox>
      <Divider sx={{ marginTop: 2 }} />
      <Feature name={FeatureTags.TRAININGMATRIX}>
        <MDBox display="flex" justifyContent="start" flexWrap="wrap" mx={0}>
          {filters?.map((val) => (
            <FilterDropdown
              label={val.inputLabel}
              name={val.inputLabel}
              value={val.selectedValue}
              handleChange={handleFilterType}
              menu={val.list}
              key={val.inputLabel}
              maxContent={defaultData.MEDIUM_CONTENT_LENGTH}
            />
          ))}
          <ResetFilterButton handleReset={handleResetFilter} />
          <BasicButton
            title={exportLoading ? ButtonTitles.EXPORTING : ButtonTitles.EXPORT}
            icon={Icons.EXPORT}
            background={Colors.WHITE}
            color={Colors.BLACK}
            border
            style={{ marginTop: 45 }}
            action={() => handleExportZipFile(exportFilters, exportTrainingMatrixCertificate)}
          />
        </MDBox>
        <MDBox mt={3} mb={3}>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
            showTotalEntries={false}
            noEndBorder
            pagination={{ variant: "gradient", color: "info" }}
            loading={loadingTrainingMatrixStatus}
            groupedData={groupedData}
            keyField="function"
            keyFieldHeader="Function"
          />
        </MDBox>
      </Feature>
    </DashboardLayout>
  );
}

TrainingMatrix.propTypes = {
  handleExportZipFile: PropTypes.func.isRequired,
  exportLoading: PropTypes.bool.isRequired,
};

export default ExportHOC(TrainingMatrix);

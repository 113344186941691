import React, { useEffect, useState } from "react";

// MUI Components
import { Divider } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";

// Custom Component
import MDBox from "components/MDBox";
import PageTitle from "examples/NewDesign/PageTitle";
import ViewToolBoxDrawer from "examples/Drawers/ToolboxTalk";
import ResetFilterButton from "components/Buttons/ResetButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import DeleteModal from "examples/modal/deleteModal/deleteModal";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import ToolboxTalkData from "layouts/toolboxTalk/data/toolboxTalkData";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ExportHOC from "examples/HigherOrderComponents/ExportHOC";

// Data tables
import DataTable from "examples/Tables/DataTable";

// Redux
import { useDispatch } from "react-redux";
import getToolBoxTalk, {
  toolBoxTalkByIdThunk,
  deleteToolBoxTalkThunk,
} from "redux/Thunks/toolBoxTalk";
import { openSnackbar } from "redux/Slice/Notification";

// Constants
import Constants, {
  PageTitles,
  Icons,
  Colors,
  defaultData,
  FeatureTags,
  ModalContent,
} from "utils/Constants";
import { paramCreater } from "utils/methods/methods";
import { projectListThunk, locationListByIdThunk, teamThunk } from "redux/Thunks/FieldsData";

// 3rd party library
import { Feature } from "flagged";

function ToolboxTalk() {
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const dispatch = useDispatch();
  const [next, setNext] = useState(0);
  const [loading, setLoading] = useState(Constants.PENDING);
  const [toolboxTalkData, setToolboxTalkData] = useState(null);
  const [toolBoxViewData, setToolBoxViewData] = useState({
    id: "",
    data: [],
    loading: true,
  });
  const [deleteToolboxTalkData, setDeleteToolboxTalkData] = useState({
    id: "",
    open: false,
  });
  const [filters, setFilters] = useState([
    {
      inputLabel: "Project",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "all",
    },
    {
      inputLabel: "Location",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "all",
    },
    {
      inputLabel: "Team",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "all",
    },
  ]);
  const [openToolboxAnchor, setOpenToolboxAnchor] = useState({ right: false });

  const getToolBoxTalkData = async (filterVale = filters) => {
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const paramData = {
      page: defaultData.PAGE,
      perPage: tablePagination.perPage,
      project: filterVale[0].selectedValue,
      location: filterVale[1].selectedValue.toLowerCase().replace(/ /g, "_"),
      team: filterVale[2].selectedValue,
    };
    const res = await dispatch(getToolBoxTalk(paramCreater(paramData)));
    setLoading(Constants.FULFILLED);
    setToolboxTalkData(res.payload.data.data);
  };

  useEffect(() => {
    (async () => {
      const temp = [...filters];
      const dropdownFormat = {
        [Constants.MONGOOSE_ID]: "",
        title: "",
      };
      if (filters[0].selectedValue !== "all") {
        const [newLocationList, newTeamList] = await Promise.all([
          dispatch(locationListByIdThunk(filters[0].selectedValue)),
          dispatch(teamThunk(filters[0].selectedValue)),
        ]);

        temp[1].list = [
          {
            [Constants.MONGOOSE_ID]: "all",
            title: newLocationList.payload.data.length > 0 ? "All" : "No data found",
          },
          ...newLocationList.payload.data,
        ];
        temp[2].list = [
          {
            [Constants.MONGOOSE_ID]: "all",
            title: newTeamList.payload.data.length > 0 ? "All" : "No data found",
          },
          ...newTeamList.payload.data.map((item) => {
            const temp2 = { ...dropdownFormat };
            temp2[Constants.MONGOOSE_ID] = item?.[Constants.MONGOOSE_ID];
            temp2.title = item?.teamsWfmName;
            return temp2;
          }),
        ];
        // Reset the Report Setup selectedValue to "all" when Project changes
        temp[1].selectedValue = "all";
        temp[2].selectedValue = "all";
      } else {
        temp[1].list = [{ [Constants.MONGOOSE_ID]: "all", title: "All" }];
        temp[2].list = [{ [Constants.MONGOOSE_ID]: "all", title: "All" }];
      }
      setFilters(temp);
    })();
  }, [filters[0].selectedValue]);

  const getToolBoxTalkDrawerData = async (id) => {
    const res = await dispatch(toolBoxTalkByIdThunk(id));
    if (res.payload.status === 200) {
      setToolBoxViewData({ data: res.payload.data.data, loading: false });
    }
  };

  useEffect(() => {
    (async () => {
      await getToolBoxTalkData();
      const projectList = await dispatch(projectListThunk());
      const temp = [...filters];
      filters[0].list = [...filters[0].list, ...projectList.payload.data];
      setFilters(temp);
    })();
  }, []);

  const handleFilterChange = (e) => {
    const temp = [...filters];
    const { value, name } = e.target;
    const indexof = filters.findIndex((filter) => filter.inputLabel === name);
    temp[indexof].selectedValue = value;

    // If the project filter changes, reset the report filter to "all"
    if (name === "Project") {
      temp[1].selectedValue = "all";
      temp[2].selectedValue = "all";
    }

    setFilters(temp);
    getToolBoxTalkData(temp);
  };

  const handleResetFilter = () => {
    const temp = filters.map((filter) => ({
      ...filter,
      selectedValue: filter.list[0][Constants.MONGOOSE_ID] || filter.list[0],
    }));
    setFilters(temp);
    getToolBoxTalkData(temp);
  };

  const handleTablePagination = async () => {
    const paramData = {
      page: next + 1,
      perPage: tablePagination.perPage,
      project: filters[0].selectedValue,
      location: filters[1].selectedValue.toLowerCase().replace(/ /g, "_"),
      team: filters[2].selectedValue,
    };
    const res = await dispatch(getToolBoxTalk(paramCreater(paramData)));
    if (res.payload.status === 200) {
      setToolboxTalkData([...toolboxTalkData, ...res.payload.data.data]);
      setNext(res.payload.data.data.length > 0 ? next + 1 : next);
    }
  };

  const handleReload = async () => {
    setLoading(Constants.PENDING);
    await getToolBoxTalkData();
    setLoading(Constants.FULFILLED);
  };

  const handleOpenToolboxDrawer = (id) => {
    setOpenToolboxAnchor({ right: true });
    getToolBoxTalkDrawerData(id);
  };

  const closeToolboxDrawer = () => {
    setOpenToolboxAnchor({ right: false });
    setToolBoxViewData({ data: [], loading: true });
  };

  const handleOpenDeleteModal = (deleteId) => {
    setDeleteToolboxTalkData({
      id: deleteId,
      open: true,
    });
  };

  const handleDeleteToolboxTalkData = async () => {
    const res = await dispatch(deleteToolBoxTalkThunk(deleteToolboxTalkData.id));
    if (res.payload.status === 200) {
      setDeleteToolboxTalkData({ id: "", open: false });
      await dispatch(
        openSnackbar({
          message: Constants.TOOLBOX_TALK_DELETE_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      setLoading(Constants.PENDING);
      await getToolBoxTalkData();
      setLoading(Constants.FULFILLED);
    } else {
      await dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const { columns, rows } = ToolboxTalkData(
    toolboxTalkData,
    handleOpenToolboxDrawer,
    handleOpenDeleteModal
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between">
        <PageTitle title={PageTitles.TOOLBOX_TALK} />
        <Feature name={FeatureTags.TOOLBOX_TALK}>
          <BasicButton
            icon={Icons.RELOAD}
            background={Colors.WHITE}
            border
            color={Colors.BLACK}
            action={handleReload}
          />
        </Feature>
      </MDBox>
      <Divider sx={{ marginTop: 2 }} />
      <Feature name={FeatureTags.TOOLBOX_TALK}>
        <MDBox display="flex" justifyContent="space-between" mx={0}>
          <MDBox display="flex wrap" flexWrap="wrap" justifyContent="start" mx={0}>
            {filters.map((val) => (
              <FilterDropdown
                key={val.inputLabel}
                label={val.inputLabel}
                name={val.inputLabel}
                defaultValue={val.selectedValue}
                value={val.selectedValue}
                handleChange={handleFilterChange}
                menu={val.list}
                style={{ marginLeft: pxToRem(1) }}
                disabled={
                  (val?.inputLabel === "Location" && filters[0]?.selectedValue === "all") ||
                  (val?.inputLabel === "Location" &&
                    filters[1]?.list[0].title === "No data found") ||
                  (val?.inputLabel === "Team" && filters[0]?.selectedValue === "all") ||
                  (val?.inputLabel === "Team" && filters[2]?.list[0].title === "No data found")
                }
                maxContent={defaultData.MEDIUM_CONTENT_LENGTH}
              />
            ))}
            <ResetFilterButton handleReset={handleResetFilter} style={{ marginLeft: "1rem" }} />
          </MDBox>
        </MDBox>
        <MDBox
          sx={{
            marginTop: pxToRem(24),
            marginBottom: pxToRem(16),
          }}
        >
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
            showTotalEntries={false}
            noEndBorder
            loading={loading}
            licenseRequired
            currentPage={tablePagination.page}
            handleTablePagination={handleTablePagination}
            handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
          />
        </MDBox>
        {openToolboxAnchor.right && (
          <ViewToolBoxDrawer
            openToolboxAnchor={openToolboxAnchor}
            closeToolboxDrawer={closeToolboxDrawer}
            toolBoxViewData={toolBoxViewData.data}
            loading={toolBoxViewData.loading}
          />
        )}
        <DeleteModal
          open={deleteToolboxTalkData.open}
          title={ModalContent.DELETE_TOOLBOX_TALK}
          message={ModalContent.DELETE_TOOLBOX_TALK_MESSAGE}
          handleClose={() => {
            setDeleteToolboxTalkData({ ...deleteToolboxTalkData, open: false });
          }}
          handleDelete={handleDeleteToolboxTalkData}
        />
      </Feature>
    </DashboardLayout>
  );
}

export default ExportHOC(ToolboxTalk);

import React, { useMemo, useEffect, useState } from "react";

// 3rd party library
import PropTypes from "prop-types";
import { useTable, usePagination, useGlobalFilter, useSortBy } from "react-table";
import jwtDecode from "jwt-decode";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Common components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import { TableCell } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";

// Constants
import Constants, { Icons, defaultData, Colors } from "utils/Constants";
import Session from "utils/Sessions";

// Redux
import { useSelector } from "react-redux";

function ReportCalculationTable({
  table,
  isSorted,
  loading,
  licenseRequired,
  backgroundColor, // New prop to specify the background color
  textColor,
  extraContent,
  extraHeaders,
  footerList,
}) {
  const [status, setStatus] = useState(Constants.PENDING);
  const ConfigData = useSelector((state) => state.config);
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);

  const { getTableProps, getTableBodyProps, headerGroups, rows, pageOptions } = tableInstance;

  // update the status on role change, when superadmin login as admin and data changes
  useEffect(() => {
    const token = jwtDecode(Session.userToken);
    const { role } = token;

    const setPending = () => setStatus(Constants.PENDING);
    const setRejected = () => setStatus(Constants.REJECTED);
    const setFulfilled = () => setStatus(Constants.FULFILLED);
    const setNoData = () => setStatus("noData");

    const isSuperAdmin =
      role === defaultData.SUPER_ADMIN_ROLE && !Session.isSuperAdminViewingAdminPanel;
    const isPending = ConfigData.loading === Constants.PENDING || loading === Constants.PENDING;
    const isRejected = ConfigData.loading === Constants.REJECTED || loading === Constants.REJECTED;
    const isFulfilledWithData =
      loading === Constants.FULFILLED && rows.length > 0 && pageOptions.length > 0;
    const isFulfilledNoData =
      loading === Constants.FULFILLED && rows.length === 0 && pageOptions.length === 0;
    const isConfigFulfilled = ConfigData.loading === "fulfilled";

    // for superadmin
    if (isSuperAdmin || !licenseRequired) {
      if (loading === Constants.PENDING) setPending();
      else if (isRejected) setRejected();
      else if (isFulfilledWithData) setFulfilled();
      else if (isFulfilledNoData) setNoData();
    }
    // for admin
    else if (isPending) setPending();
    else if (isRejected) setRejected();
    else if (isConfigFulfilled && isFulfilledWithData) setFulfilled();
    else if (isConfigFulfilled && isFulfilledNoData) setNoData();
  }, [Session.userToken, Session.isSuperAdminViewingAdminPanel, ConfigData.loading, table]);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  const handleCellBackground = (value) => {
    if (!value) return "transparent";

    // Check if the value is a date
    const datePattern = /^\d{2}-\d{2}-\d{4}$/;
    if (datePattern.test(value)) {
      return Colors.LIGHT_GREEN3;
    }

    if (value) {
      const numericValue = parseFloat(value.replace("%", ""));
      if (numericValue <= 50) {
        return Colors.PINK;
      }
      if (numericValue <= 75) {
        return Colors.LIGHT_PINK;
      }
      if (numericValue < 100) {
        return Colors.LIGHT_ORANGE1;
      }
      if (numericValue === 100) {
        return Colors.LIGHT_GREEN3;
      }
      return "transparent";
    }
    return "transparent";
  };

  return (
    <MDBox
      sx={{
        backgroundColor: "White",
        borderRadius: "10px",
        border: "1px solid #E0E6F5",
        width: "100%",
      }}
    >
      <TableContainer sx={{ boxShadow: "none" }}>
        <Table {...getTableProps()}>
          <MDBox component="thead">
            {extraHeaders.length > 0 &&
              extraHeaders?.map((headercolumns, idx) => (
                <TableRow key={headercolumns}>
                  {headercolumns?.map((column, colIdx) => (
                    <TableCell
                      key={column.key}
                      align={column.align ? column.align : "left"}
                      colSpan={column.colSpan ? column.colSpan : 1}
                      sx={({ typography: { fontWeightBold } }) => ({
                        fontSize: pxToRem(14),
                        fontWeight: fontWeightBold,
                        backgroundColor: column.Header !== "" ? Colors.WHITE : "transparent",
                        color: Colors.PRIMARY,
                        width: column.width ? column.width : "auto",
                        border: column.Header !== "" ? "1px solid #E0E6F5" : "none",
                        borderTop: "none",
                        borderBottom: idx === extraHeaders.length - 1 && "none",
                        borderRight: colIdx === headercolumns.length - 1 && "none",
                      })}
                    >
                      {column.Header}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <DataTableHeadCell
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    sorted={setSortedValue(column)}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {(() => {
              switch (status) {
                case Constants.PENDING:
                  return (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        <MDBox py={5} display="flex" justifyContent="center" alignItems="center">
                          {Icons.LOADING2}
                        </MDBox>
                      </TableCell>
                    </TableRow>
                  );

                case Constants.FULFILLED: {
                  const rowData = rows?.map((row) => {
                    const { original: locationObj } = row;
                    if (locationObj?.assets?.length === 0) {
                      return (
                        <TableRow key={locationObj?.key}>
                          <TableCell
                            key={locationObj?.key}
                            colSpan={locationObj?.colSpan || 1}
                            align={locationObj?.align || "left"}
                            rowSpan={locationObj?.rowSpan || 1}
                            width={locationObj?.width || "auto"}
                            sx={({ typography: { fontWeightBold } }) => ({
                              fontSize: pxToRem(14),
                              fontWeight: fontWeightBold,
                              color: Colors.PRIMARY,
                              backgroundColor: "transparent",
                              border: "1px solid #E0E6F5",
                              borderLeft: "none",
                            })}
                          >
                            {locationObj.location}
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            sx={({ typography: { fontWeightBold } }) => ({
                              fontSize: pxToRem(14),
                              fontWeight: fontWeightBold,
                              border: "1px solid #E0E6F5",
                              backgroundColor: "transparent",
                              color: Colors.PRIMARY,
                            })}
                          />
                          {locationObj.reports.map((report) => {
                            if (report.type === "location") {
                              return (
                                <TableCell
                                  key={report?.key}
                                  align={report?.align || "left"}
                                  rowSpan={report?.rowSpan || 1}
                                  colSpan={report?.colSpan || 1}
                                  width={report?.width || "auto"}
                                  sx={({ typography: { fontWeightBold } }) => ({
                                    fontSize: pxToRem(14),
                                    fontWeight: fontWeightBold,
                                    border: "1px solid #E0E6F5",
                                    backgroundColor:
                                      report?.reportList[0]?.bgColor || "transparent",
                                    color: Colors.PRIMARY,
                                  })}
                                >
                                  {report.reportList[0].completion}
                                </TableCell>
                              );
                            }
                            if (
                              report.type === "asset_per_location" ||
                              report.type === "multiple_assets"
                            ) {
                              return (
                                <TableCell
                                  key={report?.key}
                                  align={report?.align || "left"}
                                  rowSpan={report?.rowSpan || 1}
                                  colSpan={report?.colSpan || 1}
                                  width={report?.width || "auto"}
                                  sx={({ typography: { fontWeightBold } }) => ({
                                    fontSize: pxToRem(14),
                                    fontWeight: fontWeightBold,
                                    border: "1px solid #E0E6F5",
                                    backgroundColor:
                                      report?.reportList[0]?.bgColor || "transparent",
                                    color: Colors.PRIMARY,
                                  })}
                                >
                                  {report.reportList[0].completion}
                                </TableCell>
                              );
                            }
                            return null;
                          })}
                        </TableRow>
                      );
                    }
                    return locationObj?.assets?.map((asset, assetIndex) => (
                      <TableRow key={asset?.key}>
                        {assetIndex === 0 && (
                          <TableCell
                            key={locationObj?.key}
                            colSpan={locationObj?.colSpan || 1}
                            align={locationObj?.align || "left"}
                            rowSpan={locationObj?.rowSpan || 1}
                            width={locationObj?.width || "auto"}
                            sx={({ typography: { fontWeightBold } }) => ({
                              fontSize: pxToRem(14),
                              fontWeight: fontWeightBold,
                              color: Colors.PRIMARY,
                              backgroundColor: "transparent",
                              border: "1px solid #E0E6F5",
                              borderLeft: "none",
                            })}
                          >
                            {locationObj.location}
                          </TableCell>
                        )}
                        <TableCell
                          align={asset?.align || "left"}
                          width={asset?.width || "auto"}
                          colSpan={asset?.colSpan || 1}
                          rowSpan={asset?.rowSpan || 1}
                          sx={({ typography: { fontWeightBold } }) => ({
                            fontSize: pxToRem(14),
                            fontWeight: fontWeightBold,
                            border: "1px solid #E0E6F5",
                            backgroundColor: "transparent",
                            color: Colors.PRIMARY,
                          })}
                        >
                          {asset?.title}
                        </TableCell>
                        {locationObj.reports.map((report) => {
                          if (report.type === "location") {
                            return (
                              assetIndex === 0 && (
                                <TableCell
                                  key={report?.key}
                                  align={report?.align || "left"}
                                  rowSpan={report?.rowSpan || 1}
                                  colSpan={report?.colSpan || 1}
                                  width={report?.width || "auto"}
                                  sx={({ typography: { fontWeightBold } }) => ({
                                    fontSize: pxToRem(14),
                                    fontWeight: fontWeightBold,
                                    border: "1px solid #E0E6F5",
                                    backgroundColor:
                                      report?.reportList[0]?.bgColor || "transparent",
                                    color: Colors.PRIMARY,
                                  })}
                                >
                                  {report.reportList[0].completion}
                                </TableCell>
                              )
                            );
                          }
                          if (report.type === "asset_per_location") {
                            return (
                              <TableCell
                                align={report?.align || "left"}
                                rowSpan={report?.rowSpan || 1}
                                colSpan={report?.colSpan || 1}
                                width={report?.width || "auto"}
                                sx={({ typography: { fontWeightBold } }) => ({
                                  fontSize: pxToRem(14),
                                  fontWeight: fontWeightBold,
                                  border: "1px solid #E0E6F5",
                                  backgroundColor:
                                    report?.reportList[assetIndex]?.bgColor || "transparent",
                                  color: Colors.PRIMARY,
                                })}
                              >
                                {report.reportList[assetIndex].completion}
                              </TableCell>
                            );
                          }
                          if (report.type === "multiple_assets") {
                            return (
                              <TableCell
                                align={report?.align || "left"}
                                rowSpan={report?.rowSpan || 1}
                                colSpan={report?.colSpan || 1}
                                width={report?.width || "auto"}
                                sx={({ typography: { fontWeightBold } }) => ({
                                  fontSize: pxToRem(14),
                                  fontWeight: fontWeightBold,
                                  border: "1px solid #E0E6F5",
                                  backgroundColor:
                                    report?.reportList[assetIndex]?.bgColor || "transparent",
                                  color: Colors.PRIMARY,
                                })}
                              >
                                {report.reportList[assetIndex].completion}
                              </TableCell>
                            );
                          }

                          return null;
                        })}
                      </TableRow>
                    ));
                  });
                  return rowData;
                }

                case "noData":
                  return (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        <MDTypography variant="h4" color="secondary">
                          {Constants.NO_DATA_FOUND}
                        </MDTypography>
                      </TableCell>
                    </TableRow>
                  );

                case Constants.REJECTED:
                default:
                  return (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        <MDTypography variant="h4" color="secondary">
                          {Constants.SOMETHING_WENT_WRONG}
                        </MDTypography>
                      </TableCell>
                    </TableRow>
                  );
              }
            })()}
          </TableBody>
          <MDBox component="tfoot">
            {footerList.length > 0 &&
              footerList?.map((footerColumn, footerIdx) => (
                <TableRow key={footerColumn}>
                  {footerColumn?.map((column, nestFooterIdx) => (
                    <TableCell
                      key={column.key}
                      align={column.align ? column.align : "left"}
                      colSpan={column.colSpan ? column.colSpan : 1}
                      sx={({ typography: { fontWeightBold } }) => ({
                        fontSize: pxToRem(14),
                        fontWeight: fontWeightBold,
                        backgroundColor:
                          footerIdx === 0 && nestFooterIdx === 0
                            ? Colors.PRIMARY
                            : handleCellBackground(column.Footer),

                        color:
                          footerIdx === 0 && nestFooterIdx === 0 ? Colors.WHITE : Colors.PRIMARY,
                        width: column.width ? column.width : "auto",
                        border: column.Footer !== "" ? "1px solid #E0E6F5" : "none",
                        borderTop: "1px solid #E0E6F5",
                        borderLeft: footerIdx === 0 && nestFooterIdx === 0 && "none",
                        borderBottom: footerIdx === footerList.length - 1 && "none",
                        borderRight: nestFooterIdx === footerColumn.length - 1 && "none",
                      })}
                    >
                      {column.Footer}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </MDBox>
        </Table>
      </TableContainer>
      {extraContent}
    </MDBox>
  );
}

// Setting default values for the props of ReportCalculationTable
ReportCalculationTable.defaultProps = {
  isSorted: true,
  noEndBorder: false,
  loading: Constants.PENDING,
  licenseRequired: false,
  backgroundColor: "",
  textColor: "",
  extraContent: null,
  extraHeaders: [],
  footerList: [],
};

// Typechecking props for the ReportCalculationTable
ReportCalculationTable.propTypes = {
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  loading: PropTypes.string,
  licenseRequired: PropTypes.bool,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  extraContent: PropTypes.node,
  extraHeaders: PropTypes.arrayOf(PropTypes.array),
  footerList: PropTypes.arrayOf(PropTypes.array),
};

export default ReportCalculationTable;
